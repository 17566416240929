import React, { useEffect } from "react";

function CoinPriceMarqueeWidget() {
  const fetchWidget =  () => {
    try {
      const script = document.createElement("script");
      script.src =
        "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } catch (error) {
      console.log("Error while fetching widget");
    }
  };

  useEffect(() => {
    fetchWidget();
  }, []);

  return (
    <div>
      {/* The widget will be loaded by the script */}
      <coingecko-coin-price-marquee-widget
        coin-ids="virtual-coin,bitcoin,ethereum,binancecoin,polkadot"
        currency="usd"
        background-color="#ffffff"
        locale="en"
      />
    </div>
  );
}

export default CoinPriceMarqueeWidget;

import { useRef, useState, useEffect } from "react";
import line from "../assets/line.png";
import CountUp, { useCountUp } from "react-countup";

function Join() {
  const [endNumber, setEndNumber] = useState(1234567);
  const [currentNumber, setCurrentNumber] = useState(0);
  const counterRef = useRef(null);
  useCountUp({
    ref: counterRef,
    end: currentNumber,
    enableScrollSpy: true,
    scrollSpyDelay: 1000,
  });
  const increaseNumber = () => {
    setCurrentNumber((prevNumber) => prevNumber + 1);
  };

  // useEffect hook to call the increaseNumber function every 20 seconds
  useEffect(() => {
    const interval = setInterval(increaseNumber, 20000);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);
  return (
    <div className="container ">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-6 col-md-12 col-12">
          <div className="d-flex align-items-center" data-aos="fade-up">
            <img
              src={line}
              alt=""
              className="img-fluid"
              width="40px"
              style={{ height: "4px" }}
            />
            <h6 className="align-items-center mt-2 ms-3">
              <strong className="bold">09.</strong> Be a Part of VRC Blockchain
            </h6>
          </div>

          <h3 data-aos="fade-up">Join VRC Community</h3>
          <p data-aos="fade-up">
            Ask questions, share ideas and participate in breakfasts, hubs,
            local events with people who love VRC.
          </p>
          <div class="outer mb-4" data-aos="fade-up">
            <div class="button " style={{ width: "250px", fontSize: "12px" }}>
              <div class="text" style={{ fontSize: "12px" }}>
                {" "}
                Join Our Community
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 ">
          <div className="p-3" data-aos="fade-up">
            <div className="">
              <h5 className="text-lg-center text-start text-white">
                <CountUp
                  className="text-dark fs-2 build_btn2 "
                  end={2943930}
                  enableScrollSpy
                />
              </h5>
            </div>
            <div>
              <h6 className="text-white mt-4"> Total community members</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join;

import logo from "./logo.svg";
import "./App.css";
import LuckyNumbersGenerator from "../src/components/ReactSpinner";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import Event from "./components/Event";
import CoinPriceMarqueeWidget from "./components/HeadLine";
import TrackTicket from "./components/TrackTicket";
import { Toaster } from "react-hot-toast";
// import Footer from './components/Footer';
import SpinningWheelTabs from "../src/components/SpinningWheelTabs";

function App() {
  return (
    <div className="">
       <Router>
        <Toaster />
        <CoinPriceMarqueeWidget />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/event" element={<Event />} /> */}
          {/* <Route path="/track-ticket" element={<TrackTicket />} /> */}
        </Routes>
      </Router>
      {/* <SpinningWheelTabs /> */}
    </div>
  );
}

export default App;

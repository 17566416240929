import React from "react";
import line from "../assets/line.png";
import mater from "../assets/tokenomics.png";
const Tokonomics = () => {
  return (
    <div className="bg_token">
      <div className="container ">
        <div className="row justify-content-center text-center pt-5">
          <div className="col-lg-12 mt-5 pt-5">
            <div className="d-flex align-items-center text-center justify-content-center">
              <img
                src={line}
                alt=""
                className="img-fluid text-center"
                width="40px"
                style={{ height: "4px" }}
              />
              <h6 className="align-items-center mt-2 ms-3 text-center">
                <strong className="bold">05.</strong> Tokenomics
              </h6>
            </div>
            <h1 className="text-dark" data-aos="fade-up">
              Virtual Coin (VRC) Tokenomics
            </h1>
            <p className="para text-center" data-aos="fade-up">
              The coin distribution of VRC is as follow:
            </p>
            <img src={mater} alt="" className="img-fluid mater mb-5 pb-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokonomics;

import React, { useEffect } from "react";

function CoinGeckoWidget() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widgets.coingecko.com/coingecko-coin-converter-widget.js";
    script.async = true;

    // Append the script to the body of the document
    document.body.appendChild(script);

    return () => {
      // Cleanup by removing the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container">
      <h3 className="text-center mb-5 mt-3" data-aos="fade-up">
        VRC Calculator
      </h3>
      {/* The widget will be loaded by the script */}
      <coingecko-coin-converter-widget
        coin-id="virtual-coin"
        currency="usd"
        background-color="#ffffff"
        font-color="#4c4c4c"
        locale="en"
      />
    </div>
  );
}

export default CoinGeckoWidget;

import React from "react";
import line from "../assets/line.png";
import hackonlogo from "../assets/Hex_color.-01.svg";
import { BsArrowUpRight } from "react-icons/bs";
function Audit() {
  return (
    <div className="bg_hackon">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 col-12 mt-5 pt-5">
            <div className="d-flex align-items-center" data-aos="fade-up">
              <img
                src={line}
                alt=""
                className="img-fluid"
                width="40px"
                style={{ height: "4px" }}
              />
              <h6 className="align-items-center mt-2 ms-3">
                <strong className="bold">08.</strong> Audit
              </h6>
            </div>

            <h3 data-aos="fade-up">Audited by HACKEN</h3>
            <p data-aos="fade-up">
              Hacken is a blockchain security auditor with years of experience,
              hundreds of blockchain partners, and thousands of secured crypto
              projects. Hacken protects our technological businesses and crypto
              community worldwide with the most competitive suite of
              professional cybersecurity services. So, our Platforms will allow
              users to access our upcoming Smart contract and additional tools
              to make navigating the crypto world simple, reliable & secure.
            </p>
            <a
              className="text-decoration-none text-dark"
              href="https://hacken.io/audits/vzone-solutions/"
              target="blank"
            >
              <button className="btn build_btn2 mb-3" data-aos="fade-up">
                Audit Report <BsArrowUpRight className="ms-1 icon_main" />
              </button>
            </a>
          </div>
          <div className="col-lg-6 col-md-12 col-12 text-center">
            <img src={hackonlogo} alt="" className="img-fluid hack mt-5" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Audit;

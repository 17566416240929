import React from "react";
import line from "../assets/line.png";
import graph from "../assets/Graph 2-01.svg";
function Metaverse() {
  return (
    <div className="bg_metaverse mt-4 p-lg-0 p-3">
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-6 col-12 text-center" data-aos="fade-up">
            <img src={graph} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-12 p-2 pt-5 mt-5">
            <div className="d-flex align-items-center" data-aos="fade-up">
              <img
                src={line}
                alt=""
                className="img-fluid"
                width="40px"
                style={{ height: "4px" }}
              />
              <h6 className="align-items-center mt-2 ms-3 text-white">
                <strong className="bold">07.</strong> Metaverse
              </h6>
            </div>

            <h3 className="text-white" data-aos="fade-up">
              VRC The Metaverse
            </h3>
            <p className="text-white" data-aos="fade-up">
              VRC: Metaverse is a virtual reality space. Welcome to the VRC
              metaverse, where fantasy and reality converge, and where dreams
              have no limits. Embrace the power of the virtual realm and be part
              of an ever-evolving narrative where humanity explores, connects,
              and thrives in the boundless expanse of the digital frontier.
            </p>
            <p className="text-white" data-aos="fade-up">
              Our Goal is to provide users with a new and exciting way to earn
              passive income.
            </p>
            <p className="text-white" data-aos="fade-up">
              We are developing immersive experiences that will allow users to
              explore & learn from, and interact with the VRC Universe in
              unprecedented with a technology advances and creative minds unite,
              the metaverse becomes a powerful tool for problem-solving,
              research, and unlocking new frontiers of innovation. Empower your
              Metaverse journey with us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metaverse;

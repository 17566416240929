import React from "react";
import Header from "./components/Header";
import Welcome from "./components/Welcome";
import Benefits from "./components/Benefits";
import Evolution from "./components/Evolution";
import Dex from "./components/Dex";
import Metaverse from "./components/Metaverse";
import Audit from "./components/Audit";
import Join from "./components/Join";
import Footer from "./components/Footer";
import Tokonomics from "./components/Tokonomics";
import Pos from "./components/Pos";
import CoinListing from "./components/CoinListing";
import CoinGeckoWidget from "./components/VrcCalcuLator";
const Home = () => {
  return (
    <div>
      <Header />
      {/* <CoinGeckoWidget /> */}
      <Welcome />
      <Benefits />
      <Evolution />
      <Pos />
      <Tokonomics />
      <Dex />
      <Metaverse />
      <Audit />
      <CoinListing />
      <Join />
      <Footer />
    </div>
  );
};

export default Home;

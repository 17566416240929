import React from "react";
import line from "../../src/assets/line.png";
import welcome from "../assets/Graph 1.1-01.svg";
function Welcome() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-6 col-md-12 col-sm-12 justify-content-center text-center align-items-center">
            <div data-aos="fade-up">
              <img
                src={welcome}
                alt=""
                className="img-fluid justify-content-center align-items-center text-center img_height"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            <div>
              <div className="d-flex align-items-center" data-aos="fade-up">
                <img
                  src={line}
                  alt=""
                  className="img-fluid"
                  width="40px"
                  style={{ height: "4px" }}
                />
                <h6 className="align-items-center mt-2 ms-3">
                  <strong className="bold">01.</strong> Welcome
                </h6>
              </div>

              <h3 data-aos="fade-up">What is VRC Coin?</h3>
              <h6 data-aos="fade-up">The Cryptocurrency of Payments</h6>
              <p className="mb-0" data-aos="fade-up">
                VRC coin is a Native cryptocurrency of VRC Blockchain with the
                massive ecosystem and advance Blockchain features such as:
              </p>
              <ul className="p-2 mb-0" data-aos="fade-up">
                <li>Web3.0</li>
                <li>Metaverse</li>
                <li>Smart contracts</li>
                <li>High security</li>
                <li>High transaction speed</li>
                <li>NFT Platform Compatibility</li>
                <li className="mb-0">low transaction fees</li>
              </ul>
              <p data-aos="fade-up">
                VRC coin uses the Proof-of-stake (POS) consensus algorithm where
                validators stake their VRC as collateral for earning rewards in
                shape of VRC coin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;

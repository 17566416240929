import React from "react";
// import lbank from "../assets/lbank-logo.png";
// import lbank from "../assets/lbank-1.svg";
import lbank from "../assets/Untitled design.png";
// import bitforex from "../assets/bitforex-1.svg";
import bitforex from "../assets/bitforex.png";
import cg from "../assets/cg.png";
import cmc from "../assets/cmc.png";


import mexc from "../assets/MEXC Global.svg"
import { BsArrowUpRight } from "react-icons/bs";
import img1 from '../assets/vrcoin-listing/Audit Partner-01.png'
import img2 from '../assets/vrcoin-listing/Listing partner-01.jpg'
import img3 from '../assets/vrcoin-listing/Listing partner-02.jpg'
import img4 from '../assets/vrcoin-listing/Listing partner-03.jpg'
import img5 from '../assets/vrcoin-listing/Listing partner-04.jpg'
import img6 from '../assets/vrcoin-listing/Listing partner-05.jpg'
import img7 from '../assets/vrcoin-listing/Listing partner-06.jpg'
import img8 from '../assets/vrcoin-listing/Listing partner-07.jpg'
import img9 from '../assets/vrcoin-listing/Listing partner-08.jpg'
import img10 from '../assets/vrcoin-listing/Listing partner-09.jpg'
import img11 from '../assets/vrcoin-listing/Listing partner-10.jpg'
// import img12 from '../assets/vrcoin-listing/Listing partner-07.jpg'



const CoinListing = () => {
  return (
    <div className="container mt-5 mb-5">
     <h1 className="tex text-dark text-center">Listing & Partners</h1>
      <div className="row d-lg-flex d-block align-items-center mx-auto justify-content-center mt-5 mb-lg-5 mb-2">
      <div className="col-lg-4 d-flex p-0 mb-3 flex-column align-items-center justify-content-center mbl_wid">
          <img src="https://miro.medium.com/v2/resize:fit:1200/1*olo39D3qDjN1JrYe0Rjxow.jpeg" alt="" className="icon_lbank2" />
          <a
            href="https://www.mexc.com/exchange/VRC_USDT?_from=search"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Mexc <BsArrowUpRight />
            </h5>
          </a>
        </div>
        <div className="col-lg-4 d-flex p-0 mb-3 flex-column align-items-center justify-content-center mbl_wid">
          <img src={lbank} alt="" className="icon_lbank" />
          <a
            href="https://www.lbank.com/en-US/trade/vrc_usdt/"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Lbank <BsArrowUpRight />
            </h5>
          </a>
        </div>
      
        <div className="col-lg-4 d-flex p-0 mb-3 mt-lg-3  flex-column align-items-center justify-content-center mbl_wid">
          <img src={bitforex} alt="" className="icon_lbank3" />
          <a
            href="https://www.bitforex.com/en/spot/vrc_usdt"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Bitforex <BsArrowUpRight />
            </h5>
          </a>
        </div>

        <div className="col-lg-4 d-flex p-0 mb-3 mt-lg-3  flex-column align-items-center justify-content-center mbl_wid">
          <img src={cmc} alt="" className="icon_lbank3" />
          <a
            href="https://coinmarketcap.com/currencies/virtual-coin"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Coin Market Cap <BsArrowUpRight />
            </h5>
          </a>
        </div>

        <div className="col-lg-4 d-flex p-0 mb-3 mt-lg-3  flex-column align-items-center justify-content-center mbl_wid">
          <img src={cg} alt="" className="icon_lbank3" />
          <a
            href="https://www.coingecko.com/en/coins/virtual-coin"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Coin Gecko <BsArrowUpRight />
            </h5>
          </a>
        </div>

       

      
        {/* <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img1} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img2} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img3} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img4} alt="" className="img_setting"/>
          </div>
        </div> */}
      </div>
      {/* <div className="row d-lg-flex d-block align-items-center mx-auto justify-content-center mt-lg-5 mt-0 mb-lg-5 mb-2">
       
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img5} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img6} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img7} alt="" className="img_setting"/>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="image">
            <img src={img8} alt="" className="img_setting"/>
          </div>
        </div>
      </div> */}
      {/* <div className="row d-lg-flex d-block align-items-center mx-auto justify-content-center mt-lg-5 mt-0 mb-5">
       
       <div className="col-lg-3 col-12">
         <div className="image">
           <img src={img9} alt="" className="img_setting"/>
         </div>
       </div>
       <div className="col-lg-3 col-12">
         <div className="image">
           <img src={img10} alt="" className="img_setting"/>
         </div>
       </div>
       <div className="col-lg-3 col-12">
         <div className="image">
           <img src={img11} alt="" className="img_setting"/>
         </div>
       </div>
     </div> */}
      {/* <h5 className="text-center">More Exchanges Comming Soon.</h5> */}
    </div>
  );
};

export default CoinListing;

import axios from "axios";


// const baseUrl = "http://192.168.100.11:3030/api/event";

const baseUrl = "https://event.vrcscan.com/api/event";
// const baseUrl = "http://128.140.66.75:3030/api/event";


export const ApiInstnace = axios.create({
    baseURL: baseUrl
})
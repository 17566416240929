import React, { useEffect, useState } from "react";
import logo from "../assets/vrc_logo_2-02.svg";
import { BsArrowUpRight } from "react-icons/bs";
import mobile from "../assets/Mobile all in one_newtry-01-01.svg";
import AOS from "aos";
import WhitePaper from "../assets/VIRTUALCOIN_PDF.pdf";
import "aos/dist/aos.css";
import axios from "axios";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // Animation on initial page load
    if (initialLoad) {
      const element = document.getElementsByClassName("mobile_img")[0];
      element.style.transform = "translateY(0)";
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      // Initialize the fade-up animation on scroll
      const handleScroll = () => {
        const element = document.getElementsByClassName("mobile_img")[0];
        const scrollY = window.scrollY;

        // Adjust position based on scroll position
        let newPosition = scrollY / 5; // adjust the divisor based on your needs
        element.style.transform = `translateY(${newPosition}px)`;
      };

      // Add the event listener
      window.addEventListener("scroll", handleScroll);

      return () => {
        // Remove the event listener
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [initialLoad]);
  const [liveRate, setLiveRate] = useState(0);
  const getLivePrice = async () => {
    const { data } = await axios(
      "https://api.coingecko.com/api/v3/simple/price?ids=virtual-coin&vs_currencies=usd"
    );
    setLiveRate(data["virtual-coin"].usd);
  };
  useEffect(() => {
    getLivePrice();
    const intervalId = setInterval(() => {
      getLivePrice();
    }, 20000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      For Event
    </Tooltip>
  );
  return (
    <div className="header_bg mt-0 ">
      <div className="container p-3 mt-0 overflow">
        <div className="row justify-content-center align-items-center mb-3">
          <div className="col-lg-6 col-6 d-lg-flex d-block align-items-center">
            <img src={logo} alt="" className="img-fluid img_logo" />
            <p className="text-white border_price mt-4 pt-2 ms-3 d-lg-block d-none">
              Current Price <strong className="text-white">${liveRate}</strong>{" "}
            </p>
          </div>
          <div className="col-lg-6 col-6 text-end hover align-items-center">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <a
                href="https://event.vzsolution.com/event"
                target="blank"
                className="text-white bg-transparent border-0 text-decoration-underline"
              >
                Buy Tickets
              </a>
            </OverlayTrigger>

            <a
              href="https://vrcscan.com"
              target="blank"
              className=""
              style={{ color: "transparent" }}
            >
              {" "}
              <button className="btn build_btn p-3 mb-0 ms-3">
                Vrcscan <BsArrowUpRight className="ms-1 icon_main" />
              </button>
            </a>
          </div>
        </div>
        <div className="row justify-content-center mt-lg-5 mt-0 pt-lg-5 pt-0">
          <div className="col-lg-6">
            <h1 data-aos="fade-up">
              A Scalable & Sustainable Revolutionary Blockchain Platform.
            </h1>
            <p
              className="para text-start mt-4 mb-4 d-lg-block d-none"
              data-aos="fade-up"
            >
              The VRCScan is a Decentralized, Public Blockchain Led By a Global
              Developer Community.
            </p>
            <div
              className="d-flex"
              style={{ zIndex: "99999", position: "relative" }}
            >
              <a
                href={WhitePaper}
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <div class="outer">
                  <div class="button">
                    <div class="text">Whitepaper</div>
                  </div>
                </div>
              </a>
              <a href="https://vrcscan.com" target="blank">
                {" "}
                <button className="btn build_btn ms-lg-3">
                  Build on Vrcscan
                </button>
              </a>
            </div>
          </div>
          <div className="col-lg-6 text-center ">
            <img src={mobile} alt="" className="img-fluid mobile_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

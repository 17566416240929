import React from "react";
import line from "../assets/line.png";
import icon from "../assets/F12/Fast  Transaction.png";
import icon2 from "../assets/F12/Low Transaction fee.png";
import icon6 from "../assets/F12/Poorf of Stake.png";
import icon4 from "../assets/F12/Metaverse.png";
import icon5 from "../assets/F12/Web 3.png";
import icon3 from "../assets/F12/Proof of stake.png";
function Benefits() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-12 col-md-12 sol-sm-12">
          <div className="d-flex align-items-center" data-aos="fade-up">
            <img
              src={line}
              alt=""
              className="img-fluid"
              width="40px"
              style={{ height: "4px" }}
            />
            <h6 className="align-items-center mt-2 ms-3">
              <strong className="bold">02.</strong> Features
            </h6>
          </div>
          <h3 className="mb-4" data-aos="fade-up">
            Advance Features
          </h3>
        </div>
      </div>
      <div className="row justify-content-between align-items-center mt-5   text-center">
        <div className="col-lg-12 col-12 d-lg-flex d-block start">
          <div className=" col-lg-2 col-12 col-md-3 border box">
            <div data-aos="fade-up">
              <p>01</p>
              <img src={icon} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">Fast Transaction</h4>
            </div>
          </div>
          <div className=" col-lg-2 col-12 col-md-3 border box">
            <div data-aos="fade-up">
              <p>02</p>
              <img src={icon2} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">Low Transaction Fees</h4>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 border box">
            <div data-aos="fade-up">
              <p>03</p>
              <img src={icon3} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">Proof of Stake</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 border box">
            <div data-aos="fade-up">
              <p>04</p>
              <img src={icon4} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">Metaverse</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 border box">
            <div data-aos="fade-up">
              <p>05</p>
              <img src={icon5} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">Web 3.0</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 border box">
            <div data-aos="fade-up">
              <p className="">06</p>
              <img src={icon6} alt="" className="img-fluid p-4" />
              <h4 className="pb-3">P2E Gaming</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;

import React from "react";
import line from "../assets/line.png";
import pos from "../assets/pos-01-01.svg";
function Pos() {
  return (
    <div className="container">
      <div className="row justify-content-betwen align-items-center mt-5 pt-5">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div
            className="d-flex align-items-center text-center"
            data-aos="fade-up"
          >
            <img
              src={line}
              alt=""
              className="img-fluid text-center"
              width="40px"
              style={{ height: "4px" }}
            />
            <h6 className=" mt-2 ms-3 ">
              <strong className="bold">04.</strong> POS
            </h6>
          </div>
          <h1 className="text-dark" data-aos="fade-up">
            Proof of Stake Consensus
          </h1>
          <p data-aos="fade-up">
            VRC acts as a decentralized and EVM (Ethereum Virtual Machine)
            compatible public blockchain, driving the VRC platform and
            ecosystem. By using EVM compatible blockchain developers can run
            their code on different systems, reducing development risks. VRC is
            a Proof of Stake blockchain that is designed for low energy
            consumption and the reduction of the cost of creating blockchain
            consensus, where validators stake their VRC to earn Rewards.
          </p>
          <p data-aos="fade-up">
            In order to participate in securing the network consensus, a node
            operator must stake a minimum required amount of 1 VRC.
          </p>
        </div>
        <div
          className="col-lg-6 col-md-12 col-sm-12 justify-content-center align-items-center"
          data-aos="fade-up"
        >
          <img src={pos} alt="" className="img-fuild" />
          {/* <video  loop autoPlay muted style={{width:'300px',height:'300px'}}>
        <source src={pos} type="video/mp4" />
      </video> */}
        </div>
      </div>
    </div>
  );
}

export default Pos;

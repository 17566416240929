import React from "react";
import line from "../assets/line.png";
import evo from "../assets/3000width.png";
const Evolution = () => {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12">
            <img
              src={evo}
              alt=""
              className="img-fluid d-lg-block d-none evo_img"
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="d-flex align-items-center" data-aos="fade-right">
              <img
                src={line}
                alt=""
                className="img-fluid"
                width="40px"
                style={{ height: "4px" }}
              />
              <h6 className="align-items-center mt-2 ms-3">
                <strong className="bold">03.</strong> EVOLUTION
              </h6>
            </div>
            <h3 className="text-dark" data-aos="fade-up">
              Why VRC Blockchain?
            </h3>
            <p data-aos="fade-up">
              Blockchain is a public database that is updated and shared across
              a network of computers. The term "Block" refers to data. To be
              successful when transferring VRC to someone else, the transaction
              information must be appended to a block. The term "Chain" refers
              to how the blocks are linked together. And the computers are
              referred to as "Nodes" because they ensure that everyone who
              interacts with the Blockchain has the same data. Blockchains
              require a consensus mechanism to accomplish this distributed
              agreement.
            </p>
            <p data-aos="fade-up">
              VRC is a revolutionary Blockchain platform that is built for
              efficiency, speed, reliability and security. VRC scan is 3rd
              generation Blockchain Triple-Layered model with high speed
              transactions and ultra-secure smart contracts. VRC Blockchain is
              designed specially for payment solutions & provide model for
              worldwide business that will allow anyone to exchange & create
              value. The most important Feature of VRC blockchain is low
              transaction fees and high transaction speed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evolution;
